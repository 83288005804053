import { getServerData } from './server-data'

export const PAYPAL_DEFAULT_CDN_URI = 'www.paypalobjects.com'

/**
 * Prefaces strings with / unless they already start with one
 * @param {string} path
 * @returns {string}
 */
export function getSafePath(path = '') {
  return path.startsWith('/') ? path : `/${path}`
}

export function getCdnHost() {
  const serverData = getServerData()

  const { cdnHost = PAYPAL_DEFAULT_CDN_URI } = serverData || {}

  return cdnHost
}

export function prepPathWithCDN(path) {
  const cdnHost = getCdnHost()

  if (!path) {
    return cdnHost
  }

  const safePath = getSafePath(path)

  return `https://${cdnHost}${safePath}`
}
