import React from 'react'
import { Message } from '@paypalcorp/worldready-react'
import { usePlainStrings } from '../hooks/world-ready'

/**
 * Get plain strings from worldReady
 * @param {string} path
 * @param {object} valuesToReplace
 * @returns {string}
 */
export function useContent(path, valuesToReplace) {
  if (typeof path !== 'string') {
    throw new Error('content path should be string')
  }

  return usePlainStrings(path, valuesToReplace)
}

/**
 * Use the Message component from worldready-react to pass in variables
 * @param {string} contentPath property file path
 * @returns {function} callback function that takes
 * the property key name as id and an object of variables to replace in string
 */
export function usePrefixedMessage(contentPath) {
  // eslint-disable-next-line react/display-name
  return (id, variables) => {
    const safePath = contentPath.endsWith('.') ? contentPath : `${contentPath}.`
    const safeId = id.startsWith('.') ? id.slice(1) : id

    return <Message id={`${safePath}${safeId}`} variables={variables} />
  }
}
