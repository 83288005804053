// EXTERNAL DEPENDENCIES
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//COMPONENTS
import {
  BodyText,
  HeadingText,
  Button,
  Container,
  Row,
  CriticalIcon,
} from '@paypalcorp/pp-react'

//STYLES
import styles from './styles.module.less'

//HELPERS
import { useBusinessProfilePageContent } from '../business-profile-page/hooks'

//CONSTANTS
import { ERROR_PAGE_CONTAINER_TEST_ID } from './constants/test-ids'
import { fptiMetadata } from './constants/fpti-constants'
import { recordImpression } from '../../utils/fpti'

type ErrorPageProps = {
  profileDataNotFound?: boolean
  reloadPage?: boolean
}

/**
 * Displays a error page to go back or retry/refresh the page
 * @param {object} props
 * @param {boolean} props.profileDataNotFound - if true, will render error messages for profile data not found
 * @param {boolean} props.reloadPage - if true, will render Try Again button, else render Go back button
 */
export default function ErrorPage({
  profileDataNotFound = false,
  reloadPage = true,
}: Readonly<ErrorPageProps>) {
  const useContent = useBusinessProfilePageContent()
  const navigate = useNavigate()

  //contents
  const noProfileHeading = useContent('profileNotFoundHeading')
  const noProfileTextLine = useContent('profileNotFoundTextLine')
  const heading = useContent('errorPageHeading')
  const textLine1 = useContent('errorPageTextLine1')
  const textLine2 = useContent('errorPageTextLine2')
  const goBackBtn = useContent('errorPageGoBackBtn')
  const tryAgainBtn = useContent('errorPageTryAgainBtn')

  const [reload, setReload] = useState(reloadPage)
  useEffect(() => {
    //identify and go back to the webpage from where the link was clicked
    const referral = document.referrer
    if (referral && referral !== window.location.href && profileDataNotFound) {
      setReload(false)
    }
  }, [profileDataNotFound])

  useEffect(() => {
    const eventName = profileDataNotFound
      ? fptiMetadata.NO_PROFILE_EVENT
      : fptiMetadata.GENERIC_ERROR_EVENT
    const page = profileDataNotFound
      ? fptiMetadata.NO_PROFILE_ERROR_PAGE
      : fptiMetadata.GENERIC_ERROR_PAGE
    recordImpression({
      page,
      eventName,
      payerId: null,
      handle: null,
    })
  }, [profileDataNotFound])

  const handleClick = () => (reload ? navigate(0) : navigate(-1)) //button click action - reload page or go back to previous page

  return (
    <Container
      className={styles.errorContainer}
      data-testid={ERROR_PAGE_CONTAINER_TEST_ID}
    >
      <Row>
        <CriticalIcon
          color='sysColorAccent2Main'
          className={styles.criticalIcon}
        />
      </Row>
      <Row className={styles.textContainer}>
        <Row className={styles.headingRow}>
          <HeadingText size='sm'>
            {profileDataNotFound ? noProfileHeading : heading}
          </HeadingText>
        </Row>
        <Row className={styles.textRow}>
          <BodyText>
            {profileDataNotFound ? noProfileTextLine : textLine1}
          </BodyText>
          <BodyText>{profileDataNotFound ? null : textLine2}</BodyText>
        </Row>
      </Row>
      <Button onClick={handleClick} fullWidthOnMobile={false}>
        {reload ? tryAgainBtn : goBackBtn}
      </Button>
    </Container>
  )
}
