// EXTERNAL DEPENDENCIES
import React from 'react'
import { createRoot } from 'react-dom/client'

// COMPONENTS
import AppWrapper from './app-wrapper'

// CONSTANTS
import { initServerData } from './utils/server-data'

/**
 * Renders AppWrapper component to "root" DOM element
 */
export function render() {
  initServerData()

  let root = document.getElementById('root')

  if (!root) {
    root = document.createElement('div')
    root.id = 'root'
    document.body.appendChild(root)
  }

  const container = createRoot(root)

  container.render(<AppWrapper />)
}
