import { usePrefixedMessage } from '../../../utils/content'
import { BUSINESS_PROFILE_REDUCER } from '../../../constants/redux'
import { useReduxState } from '../../../hooks/redux'
import { usePrefixedContent } from '../../../utils/content-helpers'
import { BUSINESS_PROFILE_PAGE_CONTENT_PATH } from '../constants/content-paths'
import { RootState } from '@/redux/store'

/**
 * Use content specific to the label details page properties
 */
export function useBusinessProfilePageContent() {
  return usePrefixedContent(BUSINESS_PROFILE_PAGE_CONTENT_PATH)
}

/**
 * Use content specific to the label details page properties
 */
export function useBusinessProfilePageMessage() {
  return usePrefixedMessage(BUSINESS_PROFILE_PAGE_CONTENT_PATH)
}

/**
 * Retrieves the business profile redux state
 */
export function useBusinessProfileReduxState() {
  return useReduxState(
    BUSINESS_PROFILE_REDUCER,
  ) as RootState[typeof BUSINESS_PROFILE_REDUCER]
}
