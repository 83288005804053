const FALLBACK_CONTENT = {
  'business-profile-page.index.errorPageHeading': 'Something went wrong',
  'business-profile-page.index.errorPageTextLine1':
    "Looks like we're having trouble loading that profile right now",
  'business-profile-page.index.errorPageTextLine2':
    'Try refreshing the page or come back later.',
  'business-profile-page.index.profileNotFoundHeading':
    'We can’t seem to find that profile',
  'business-profile-page.index.profileNotFoundTextLine':
    'Double check that you have the right address or try coming back again later.',
  'business-profile-page.index.errorPageGoBackBtn': 'Go Back',
  'business-profile-page.index.errorPageTryAgainBtn': 'Try Again',
}

export default FALLBACK_CONTENT
