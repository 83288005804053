export const PROFILE_PHOTO_DIMENSION_REQUIREMENTS = {
  minPixelWidth: 160,
  minPixelHeight: 160,
  maxPixelWidth: 288,
  maxPixelHeight: 288,
}

export const COVER_PHOTO_DIMENSION_REQUIREMENTS = {
  minPixelWidth: 484,
  minPixelHeight: 160,
  maxPixelWidth: 1290,
  maxPixelHeight: 427,
}
