// EXTERNAL DEPENDENCIES
import { DisplayNames } from '@paypalcorp/worldready'

// HELPERS
import { useWorldReadyContext } from './environment'

/**
 * Get WorldReady Display Names for the given element or type. If
 * a lookup value is given, that value will be selected from the
 * found list of Display Names
 * @param element
 * @param type
 * @param region optional region, like 'US'
 * @param lookup the actual address value to be "translated"
 */
export function useDisplayableNames({
  element = '',
  region,
  type = 'portableAddressValues',
  style,
  lookup,
}: {
  element?: string
  region?: string
  type?: string
  style?: string
  lookup?: string | string[]
}) {
  try {
    const worldReady = useWorldReadyContext()
    const addressRegion = region || worldReady.region

    const displayNames = new DisplayNames(worldReady, {
      type,
      element,
      addressRegion,
      style,
    })

    let foundLookup = ''

    if (lookup) {
      foundLookup = displayNames.select(lookup)
    }

    return [displayNames, foundLookup]
  } catch (_e) {
    return [{}, '']
  }
}

/**
 * Gets the list of Display Names for that region's adminArea1 values. If adminArea1ToLookUp
 * is supplied, the individual display name for that adminArea1 will be returned instead
 * @param adminArea1ToLookUp optional actual address value to "translate"
 * @param region optional region, like 'US'
 */
export function useAdminArea1DisplayNames(
  adminArea1ToLookUp?: string,
  region?: string,
) {
  return useDisplayableNames({
    element: 'adminArea1',
    lookup: adminArea1ToLookUp,
    region,
  })
}

/**
 * Gets the list of Display Names for that areas
 * @param countryCodeToLookUp optional actual address value to "translate"
 * @param region optional region, like 'US'
 */
export function useCountryCodeDisplayNames(
  countryCodeToLookUp?: string,
  region?: string,
) {
  return useDisplayableNames({
    type: 'region',
    lookup: countryCodeToLookUp,
    region,
  })
}
