// EXTERNAL DEPENDENCIES
import React, { useEffect } from 'react'
import { matchPath, Outlet, useLocation } from 'react-router'

// CONSTANTS
import { APP_NAME } from '@/utils/fpti-constants'

function trackCPL(path: string) {
  if (typeof window.PAYPAL?.analytics?.setup === 'function') {
    const pathList = [
      {
        pathPattern: '/profile/:handle',
        page: 'business_profile_page',
      },
      {
        pathPattern: '/profile',
        page: 'create_profile_page',
      },
    ]
    const { page } =
      pathList.find(({ pathPattern }) => matchPath(pathPattern, path)) || {}

    if (page) {
      const options = {
        data: {
          page: page,
          event_name: `visited_${page}`,
          comp: APP_NAME,
        },
        trackCPL: true,
        trackCWV: true,
      }

      window.PAYPAL.analytics.setup(options)
    }
  }
}

/**
 * RouterTracker detects route changes to fire client redirection CPL call
 * */
export default function RouteTracker() {
  const location = useLocation()

  useEffect(() => {
    trackCPL(location.pathname)
  }, [location.pathname])

  return <Outlet />
}
