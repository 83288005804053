export const APP_BASE_ROUTE = '/biz'
export const API_ROOT = `${APP_BASE_ROUTE}/api`
export const PROFILES_API = `${API_ROOT}/profiles`
export const VALIDATION_API = `${API_ROOT}/validate`

export const profileApis = {
  PROFILES_API,
  PROFILE_STATISTICS_API: `${PROFILES_API}/statistics`,
  VALIDATE_PROFILES_API: `${VALIDATION_API}/profile`,
  VALIDATE_BUSINESS_HANDLE_API: `${VALIDATION_API}/business-handle`,
  VALIDATE_BUSINESS_ASSETS_API: `${VALIDATION_API}/business-assets`,
  VALIDATE_ACCOUNT_COMPLETION_API: `${VALIDATION_API}/account-completion`,
  PROFILE_DATA: `${APP_BASE_ROUTE}/profile-data`,
  PROFILE_LOGO: `${APP_BASE_ROUTE}/profile-logo`,
  UNSUBSCRIBE: `${APP_BASE_ROUTE}/subscription/unsubscribe`,
  SUBSCRIBE: `${APP_BASE_ROUTE}/subscription/subscribe`,
}

export const appRoutes = {
  BUSINESS_PROFILE_ROUTE: '/profile',
}

const externalPayPalRoutes = {
  EDIT_BUSINESS_PROFILE_SETTINGS: '/businessprofile/settings/info/edit',
  PAYMENT_TRANSFER_HOMEPAGE: '/myaccount/transfer/homepage/external/profile',
}

export const logRoutes = {
  CLIENT_LOG_ROUTE: `${APP_BASE_ROUTE}/log`,
}

export default {
  ...appRoutes,
  ...profileApis,
  ...externalPayPalRoutes,
  ...logRoutes,
}

export const PRODUCTION_BIZ_URL = 'https://www.paypal.com/biz'
export const PRODUCTION_PROFILE_URL = `${PRODUCTION_BIZ_URL}/profile`
