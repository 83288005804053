/**
 * Grabs the data we dump from the server into the DOM and exports them as a singleton.
 * This allows us to conveniently dump server data into the dom and use this to load
 * that data into our app.
 */
// because the way webpack is implemented, you can import this file anywhere
// in the codebase like so:
// import serverData from 'utils/server-data'
import { WorldReady } from '@paypalcorp/worldready'

export type ServerData = {
  _csrf: string
  worldReady: object
  locality: {
    locale: string
  }
  requestURI: string
  elmo: {
    userCanPreviewProfile?: boolean
  }
}

const serverData = {} as ServerData

function initServerData(preloadedState: object) {
  try {
    if (preloadedState) {
      Object.assign(serverData, preloadedState)
    } else {
      const dataNode = document.getElementById('sellermgmtnodeweb-server-data')

      if (!dataNode) {
        console.error(
          'There was no element with the id of sellermgmtnodeweb-server-data.',
        )
        return
      }

      const serverDataString = dataNode.innerHTML
      Object.assign(serverData, JSON.parse(serverDataString))

      if (dataNode.parentElement) {
        dataNode.parentElement.removeChild(dataNode) // clean up the DOM
      }
    }

    if (process.env.NODE_ENV !== 'test') {
      WorldReady.load(serverData.worldReady)
    }
  } catch (_err) {
    // caught error
  }

  return serverData
}

function getServerData() {
  return serverData
}

export default serverData
export { initServerData, getServerData }
/* eslint no-console:0 */
