// EXTERNAL DEPENDENCIES
import { AddressFormat } from '@paypalcorp/worldready'

// HELPERS
import { useAdminArea1DisplayNames, useWorldReadyContext } from './'

// CONSTANTS
import {
  CITY_STATE_COUNTRY_ADDRESS_PREFERENCE,
  DO_NOT_SHOW_ADDRESS_PREFERENCE,
  FULL_ADDRESS_ADDRESS_PREFERENCE,
  STATE_COUNTRY_ADDRESS_PREFERENCE,
} from '@/constants/addresses'

// TYPINGS
import { PortableAddress } from '../../types/world-ready'

/**
 * Get new WorldReady AddressFormat constructor
 * @param region region of address to format, defaults to worldReady.region
 * @param style formatting style, defaults to 'international-multiline'
 * @param type address type
 */
export function useAddressFormatter(
  type?: string,
  region?: string,
  style?: string,
) {
  const worldReady = useWorldReadyContext()
  const addressRegion = region || worldReady.region

  return new AddressFormat(worldReady, {
    addressRegion,
    type,
    style: style || 'singleline',
  })
}

/**
 * Get new WorldReady AddressFormat constructor
 * @param region region of address to format, defaults to worldReady.region
 * @param style formatting style, defaults to 'international-multiline'
 * @param type address type
 */
export function useAddressFormatting(
  addressToFormat: Partial<PortableAddress>,
  type?: string,
  region?: string,
  style?: string,
) {
  const worldReady = useWorldReadyContext()
  const addressRegion = region || worldReady.region

  return new AddressFormat(worldReady, {
    addressRegion,
    type,
    style: style || 'singleline',
  }).format({ ...addressToFormat })
}

export function useFormattedAddresses(address: PortableAddress) {
  const addressFormatter = useAddressFormatter()
  const {
    addressLine1,
    addressLine2,
    adminArea1,
    adminArea2,
    countryCode,
    postalCode,
  } = address || {}

  // FULL ADDRESS OPTION
  const fullAddressOption = addressFormatter.format({
    addressLine1,
    addressLine2,
    adminArea1,
    adminArea2,
    postalCode,
  })

  // CITY/STATE OPTION
  const cityStateOption = addressFormatter.format({
    adminArea1,
    adminArea2,
  })

  // STATE/COUNTRY OPTION
  const adminArea1DisplayName = useAdminArea1DisplayNames(adminArea1)[1]
  const stateCountryOption =
    adminArea1DisplayName && countryCode
      ? `${adminArea1DisplayName}, ${countryCode}`
      : ''

  return {
    [DO_NOT_SHOW_ADDRESS_PREFERENCE]: '',
    [FULL_ADDRESS_ADDRESS_PREFERENCE]: fullAddressOption,
    [CITY_STATE_COUNTRY_ADDRESS_PREFERENCE]: cityStateOption,
    [STATE_COUNTRY_ADDRESS_PREFERENCE]: stateCountryOption,
  }
}
