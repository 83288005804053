import { makeRequest } from '../api'
import serverData from '../utils/server-data'
import { useMutation, useQuery } from '@tanstack/react-query'

/**
 * Hook to make axios request
 */
export function useMakeRequest<T>(url: string, additionalOptions = {}) {
  return useQuery<T, Error, T, [url: string]>({
    queryKey: [url],
    queryFn: () => makeRequest(url),
    refetchOnWindowFocus: false,
    ...additionalOptions,
  })
}

/**
 * Hook intended to make mutative (POST/PATCH/PUT) axios request
 */
export function useMutativeRequest<T>(
  url: string,
  axiosOptions = {},
  additionalOptions = {},
) {
  return useMutation<
    T,
    Error,
    T,
    [url: string, { headers: object; data: object }?]
  >({
    mutationFn: (requestBody: unknown) => {
      return makeRequest(url, {
        method: 'POST',
        ...axiosOptions,
        data: { ...(requestBody as object), _csrf: serverData?._csrf },
      })
    },
    ...additionalOptions,
  })
}
