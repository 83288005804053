// EXTERNAL DEPENDENCIES
import { useEffect } from 'react'

// HELPERS
import { trackImpression } from '../utils/fpti'

// TYPES
import { UnformattedFptiOptions } from '../types'

/**
 * Add and remove event listeners to window
 * @param event event to listen for
 * @param handler callback to handle event action
 */
export function useEventListening(event: string, handler: any) {
  useEffect(() => {
    window.addEventListener(event, handler)

    return () => window.removeEventListener(event, handler)
  }, [event, handler])
}

/**
 * Initializes impression tracking upon options change
 * @param options to be tracked
 */
export function useImpressionTracking(options: UnformattedFptiOptions) {
  useEffect(() => {
    if (options.eventName) {
      options.withMetrics = true

      trackImpression(options)
    }
  }, [options, options.eventName])
}
