//String constants used for fpti functions

export const APP_NAME = 'sellermgmtnodeweb'
export const DEFAULT_PGRP = 'main:bizprofileweb:sellermgmt:viewprofile'
export const DEFAULT_PAGE = 'viewprofile'
export const EVENTS = {
  CLICK: 'cl',
}
export const ITEM = 'default'
export const SPACE_KEY = 'SKZKCL'

export const COMPONENT_NAME = 'business_profile'

export const DATA_FPTI = 'data-fpti'

// TAGS START
export const EVENT_NAME = 'event_name'
export const EVENT_TYPE = 'event_type'
export const LINK_NAME = 'link_name'
// TAGS ENDS
