// EXTERNAL DEPENDENCIES
import React from 'react'

// COMPONENTS
import { Container, LoadingSpinner } from '@paypalcorp/pp-react'

// CONSTANTS
import { LOADING_CONTAINER_TEST_ID } from '../../../../constants/test-ids'

// STYLES
import styles from './styles.module.less'

/**
 * Displays a loading spinner that spans the entire screen
 */
export default function CenteredLoadingSpinner({
  height,
}: {
  height?: string
}) {
  return (
    <Container
      style={{ height }}
      className={styles.loadingContainer}
      data-testid={LOADING_CONTAINER_TEST_ID}
    >
      <LoadingSpinner size='lg' />
    </Container>
  )
}
