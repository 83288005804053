// EXTERNAL DEPENDENCIES
import React, { useEffect } from 'react'

// HELPERS
import serverData from '../../../../utils/server-data'

// CONSTANTS
import {
  SCRIPT_FNCLS_KEY,
  SCRIPT_FNCLS_VALUE,
  FRAUD_NET_CONFIG_ID,
  FRAUD_NET_SCRIPT_TYPE,
} from './constants/index'
import { FRAUD_NET_NO_SCRIPT_TEST_ID } from './constants/test-ids'

/**
 * FraudNet component loads the necessary scripts for tracking the data for the RDA team.
 * This component includes scripts inclusion for both JS supported and unsupported browsers.
 * The config values are taken from server config and used on the script tag.
 * https://paypal.atlassian.net/wiki/spaces/RiskDataAcquisition/pages/599755162/FraudNet+v2.x+Developer+Guide+for+PayPal+Apps
 * @returns {JSX.Element}
 */
export default function FraudNet() {
  const { fraudNet } = serverData
  const { config, scriptUrl, noScriptUrl } = fraudNet
  const { fraudNetSessionId, fraudNetPageFlowId } = config
  const replacedNoScriptUrl = noScriptUrl
    .replace('[guid]', fraudNetSessionId)
    .replace('[flowid]', fraudNetPageFlowId)

  useEffect(() => {
    function injectConfig() {
      const script = document.createElement('script')
      script.id = FRAUD_NET_CONFIG_ID
      script.type = FRAUD_NET_SCRIPT_TYPE
      script.setAttribute(SCRIPT_FNCLS_KEY, SCRIPT_FNCLS_VALUE)
      const configuration = {
        f: fraudNetSessionId,
        s: fraudNetPageFlowId,
      }
      script.text = JSON.stringify(configuration)
      document.body.appendChild(script)
    }

    function loadFraudNetJS() {
      const script = document.createElement('script')
      script.src = scriptUrl
      document.body.appendChild(script)
    }
    injectConfig()
    loadFraudNetJS()
  }, [fraudNetPageFlowId, fraudNetSessionId, scriptUrl])

  return (
    <noscript data-testid={FRAUD_NET_NO_SCRIPT_TEST_ID}>
      <img src={replacedNoScriptUrl} alt='' />
    </noscript>
  )
}
