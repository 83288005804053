// EXTERNAL DEPENDENCIES
import { MessageFormat } from '@paypalcorp/worldready'

// HELPERS
import { useWorldReadyContext } from './'

/**
 * Get plain strings using WorldReady MessageFormat function
 * @param id path to locales
 * @param replacementValues object containing key/value string replacements. For example,
 * the property "Hi {newPersonName}, my name is {greeterName}." should be given an object such as
 * { newPersonName: 'Evan', greeterName: 'Simon' }
 */
export function usePlainStrings(id: string, replacementValues: object) {
  const worldReady = useWorldReadyContext()
  return new MessageFormat(worldReady, { id }).format(replacementValues)
}
