// CONTENT PATH
export const CREATE_PROFILE_PAGE_CONTENT_PATH = 'create-profile-page.index.'

// PANEL IDS START
export const profileDescriptionPanelId = 0
export const businessInformationPanelId = 1
export const profilePhotosPanelId = 2
export const reviewProfilePanelId = 3
// PANEL IDS END

export const MAX_DESCRIPTION_CHARACTER_LENGTH = 250

export const ERROR_ALERT_TRY_AGAIN_LINK = 'error-alert-try-again-link'

// ERROR CODES
export const RISK_ERROR_CODE = '1018'

export * from './test-ids'
