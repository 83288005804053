// EXTERNAL DEPENDENCIES
import React from 'react'
import { Routes, Route } from 'react-router-dom'

// COMPONENTS
import CenteredLoadingSpinner from './common/components/loading/centered-loading-spinner'
import RouteTracker from './common/components/tracking/route-tracking'
import ErrorBoundary from './common/components/ui/errors/error-boundary'

// SCREENS
import ErrorPage from './screens/error-page'

// HELPERS
import { useEventListening } from './hooks'
import { trackClicksFromElementData } from './utils/fpti'
import { logError } from './utils/client-error'

// LAZY-LOADED SCREENS START
const BusinessProfilePage = React.lazy(
  () => import('./screens/business-profile-page'),
)
const CreateProfilePage = React.lazy(
  () => import('./screens/create-profile-page'),
)
// LAZY-LOADED SCREENS END

export default function App() {
  useEventListening('click', trackClicksFromElementData)

  // log all uncaught js errors
  window.onerror = (
    msg: string,
    _source: string,
    _line: number,
    _col: number,
    error: Error,
    // eslint-disable-next-line max-params
  ) => {
    logError({
      message: msg,
      stack: error?.stack,
    })
  }

  return (
    <div>
      <ErrorBoundary>
        <React.Suspense fallback={<CenteredLoadingSpinner height={'70vh'} />}>
          <Routes>
            <Route element={<RouteTracker />}>
              <Route path='/profile' element={<CreateProfilePage />} />
              <Route
                path='/profile/:handle'
                element={<BusinessProfilePage />}
              />
              <Route path='*' element={<ErrorPage reloadPage={false} />} />
            </Route>
          </Routes>
        </React.Suspense>
      </ErrorBoundary>
    </div>
  )
}
