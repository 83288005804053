import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './root-reducer'

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})

export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch
export type DispatchFunction = () => AppDispatch
export type RootState = ReturnType<AppStore['getState']>
