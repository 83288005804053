// EXTERNAL DEPENDENCIES
import React from 'react'

// HELPERS
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react'

// TYPES
import { WorldReady } from '../../types/world-ready'

/**
 * Get WorldReadyContext as given to WorldReadyContextProvider in render
 */
export function useWorldReadyContext(): WorldReady {
  return React.useContext(WorldReadyContext)
}
