// HELPERS
import { makeRequest } from '@/api'

// CONSTANTS
import serverData from '../utils/server-data'
import { routes } from '@/api/constants'

/**
 * Sends error to the server to be logged to Cal
 */
export function logError(error: { message: string; stack?: string }) {
  return makeRequest(routes.CLIENT_LOG_ROUTE, {
    method: 'POST',
    data: {
      request: error,
      _csrf: serverData?._csrf,
    },
  })
}
