/* eslint-disable indent */
import React from 'react'
import { FormattedFptiOptions, UnformattedFptiOptions } from '../types'

declare global {
  interface Window {
    PAYPAL: any
  }
}
// TODO: We need to go through and finalize naming etc... here. This was currently used for testing
import {
  EVENTS,
  APP_NAME,
  ITEM,
  DEFAULT_PGRP,
  DEFAULT_PAGE,
  SPACE_KEY,
  COMPONENT_NAME,
  DATA_FPTI,
} from './fpti-constants'

const analyticsInstance = window?.PAYPAL?.analytics?.Analytics
  ? new window.PAYPAL.analytics.Analytics()
  : {
      record: (obj: UnformattedFptiOptions) => obj,
      recordImpression: (obj: UnformattedFptiOptions) => obj,
      recordEvent: (obj: UnformattedFptiOptions) => obj,
      recordClick: (obj: UnformattedFptiOptions) => obj,
    }

export const recordClick = (options: UnformattedFptiOptions = {}) => {
  try {
    if (
      window.PAYPAL &&
      window.PAYPAL.analytics &&
      window.PAYPAL.analytics.Analytics &&
      window.PAYPAL.analytics.Analytics.prototype &&
      window.PAYPAL.analytics.Analytics.prototype.getRequestData &&
      window.PAYPAL.analytics.Analytics.prototype.recordClick
    ) {
      const data = window.PAYPAL.analytics.Analytics.prototype.getRequestData()
      const fpti = Object.assign({}, data) as FormattedFptiOptions

      // Set link name - Does not look at the metaobject because each individual item should have their own link
      fpti.link = options.link || data.link

      // Set page group and page name
      fpti.pgrp = options.pgrp || DEFAULT_PGRP
      fpti.page = options.page || DEFAULT_PAGE
      // Set page group link and page link
      fpti.pglk = fpti.link ? `${fpti.pgrp}|${fpti.link}` : data.pglk
      fpti.pgln = fpti.link ? `${fpti.page}|${fpti.link}` : data.pgln

      // Clearing out error props in case they exist
      fpti.erpg = null
      fpti.erfd = null
      fpti.eccd = null

      // Setting component
      fpti.e = EVENTS.CLICK
      fpti.comp = options.comp || APP_NAME
      fpti.cust = options.cust
      fpti.mid = options.mid
      fpti.space_key = SPACE_KEY
      fpti.event_name = options.eventName

      window.PAYPAL.analytics.Analytics.prototype.recordClick({ data: fpti })
    }
    // eslint-disable-next-line no-empty
  } catch (_excep) {}
}

// TODO: We need to go through and finalize naming etc... here. This was currently used for testing
export const recordImpression = (fptiMetadata: UnformattedFptiOptions = {}) => {
  if (
    window.PAYPAL &&
    window.PAYPAL.analytics &&
    window.PAYPAL.analytics.Analytics &&
    window.PAYPAL.analytics.Analytics.prototype &&
    window.PAYPAL.analytics.Analytics.prototype.recordImpression
  ) {
    window.PAYPAL.analytics.Analytics.prototype.recordImpression(null, {
      pgrp: fptiMetadata.pgrp || DEFAULT_PGRP,
      page: fptiMetadata.page || DEFAULT_PAGE,
      mid: fptiMetadata.mid,
      comp: APP_NAME,
      item: ITEM,
      rsta: fptiMetadata.rsta,
      event_name: fptiMetadata.eventName,
      evnt_type: fptiMetadata.eventType,
      encr_cust_id: fptiMetadata.consumerId || null,
      encr_rcvr_id: fptiMetadata.payerId,
      merchant_profile_id: fptiMetadata.handle,
      space_key: SPACE_KEY,
    })
  }
}

export function transformOptionKeys(options: UnformattedFptiOptions) {
  const data = {} as FormattedFptiOptions

  // Set page group and page name
  data.page_name = data.page = options.pageName || DEFAULT_PAGE
  data.page_group = data.pgrp = options.pageGroup || DEFAULT_PGRP
  data.event_name = options.eventName
  data.event_type = options.eventType

  // Clearing out error props in case they exist
  data.erpg = null
  data.erfd = null
  data.eccd = null

  // Setting component and environment
  data.space_key = SPACE_KEY
  data.component = options.component || COMPONENT_NAME
  data.comp = options.component || APP_NAME
  data.platform = 'Web'

  if (typeof options.ppMeActivated === 'boolean') {
    data.pp_me_activated = options.ppMeActivated
  }

  data.login = options.loggedIn || false

  return data
}

export function trackClick(options: UnformattedFptiOptions) {
  options.eventType = 'cl'

  const data = transformOptionKeys(options)

  analyticsInstance.recordClick({ data })
}

/**
 * Track FPTI data stored in an data-fpti HTML element
 */
export function trackClicksFromElementData(e: React.PointerEvent<HTMLElement>) {
  const fptiOptionsAsAString = e.target?.getAttribute?.(DATA_FPTI)

  if (fptiOptionsAsAString) {
    const parsedFptiOptions = JSON.parse(fptiOptionsAsAString)

    trackClick(parsedFptiOptions)
  }
}

export function trackImpression(
  options: UnformattedFptiOptions,
  additionalOptions = {},
) {
  options.eventType = 'im'

  if (options.withMetrics) {
    delete options.withMetrics

    additionalOptions = { ...additionalOptions, trackCPL: true, trackCWV: true }
  }

  const data = transformOptionKeys(options)

  const impression = { data, ...additionalOptions }

  window?.PAYPAL?.analytics?.Analytics?.prototype?.recordImpression(impression)
}

export const stringifyFptiProps = (fpti: UnformattedFptiOptions) =>
  JSON.stringify(fpti)
