import { useContent } from './content'
import { prepPathWithCDN } from './location-helpers'
import fallbackContent from '../constants/fallback-content'

/**
 * Find all content list items for a given path
 * e.g. for content named listItem1, listItem2, listItem3, given path is listItem
 * @param {string} path
 * @returns {array}
 */
export function useListItems(path) {
  const listItems = []
  for (let i = 1; i < Number.MAX_SAFE_INTEGER; i++) {
    const lookup = `${path}${i}`
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const listItem = useContent(lookup)
    if (listItem === lookup) {
      break
    }
    listItems.push(listItem)
  }
  return listItems
}

/**
 * Initialize function with main content prefix and
 * invoke subsequent function with specific path
 * @param {string} prefix
 * @param {string} path
 * @param {object} valuesToReplace
 * @returns {function}
 */
export function usePrefixedContent(prefix) {
  return (path, valuesToReplace) => {
    const contentLocation = `${prefix}${path}`
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const content = useContent(contentLocation, valuesToReplace)
    const contentNotFound = content === contentLocation
    if (contentNotFound) {
      return fallbackContent[content] || ''
    }
    return content
  }
}

/**
 *
 * @returns url of latest PayPal logo
 */
export function getPayPalLogoUrl() {
  return prepPathWithCDN('/paypal-ui/logos/svg/paypal-wordmark-color.svg')
}

/**
 * Add a custom decorator in between a list of transaction item options for UI presentation
 * Will return:
 * string1 / string2 / ...stringX
 * @param {{ selection: string }[]} arrayOfOptions
 * @param {string} decorator used to separate the list of strings (defaults to "/")
 * @returns {string} separated by a decorator
 */
export function separateOptionsWithDecorator(arrayOfOptions, decorator = '/') {
  return (
    arrayOfOptions?.reduce((finalDecoratorString, { selection }, idx) => {
      const notLastItemInArray = idx !== arrayOfOptions.length - 1
      const usedDecorator = notLastItemInArray ? ` ${decorator} ` : ''

      finalDecoratorString += selection ? `${selection}${usedDecorator}` : ''

      return finalDecoratorString
    }, '') || ''
  )
}
