// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__loadingContainer___crx25 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://common/components/loading/centered-loading-spinner/styles.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF","sourcesContent":[".loadingContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": "styles-module__loadingContainer___crx25"
};
export default ___CSS_LOADER_EXPORT___;
