import axios, { type AxiosRequestConfig } from 'axios'

/**
 * Make axios request with error handling
 */
export async function makeRequest<T>(
  url: string,
  options: AxiosRequestConfig = {},
): Promise<T> {
  try {
    const customHeaders = { 'X-Requested-With': 'XMLHttpRequest' }
    options.headers = { ...customHeaders, ...options.headers }

    const response = await axios.request({
      url,
      ...options,
    })

    return response?.data
  } catch (error) {
    const safeError = error || new Error()
    const loggedOutErrorCodes = [401]
    const userIsLoggedOut = loggedOutErrorCodes.includes(
      safeError.response.status || safeError.response.statusCode,
    )

    if (userIsLoggedOut) {
      window.location.href = safeError.response.headers.location
    }

    safeError.data = safeError.response
    safeError.status =
      safeError.response.status || safeError.response.statusCode

    throw safeError
  }
}
