// EXTERNAL DEPENDENCIES
import { useEffect, useState } from 'react'

// HELPERS
import { usePrefixedContent } from '@/utils/content-helpers'
import { logError } from '@/utils/client-error'

// CONSTANTS
import {
  INVALID_STORE_URL_ERROR_CODE,
  PROFILE_DESCRIPTION_CONTAINS_EMAIL_ERROR_CODE,
  PROFILE_DESCRIPTION_CONTAINS_HTML_TAG_ERROR_CODE,
  PROFILE_DESCRIPTION_CONTAINS_PHONE_NUMBER_ERROR_CODE,
  PROFILE_DESCRIPTION_CONTAINS_URL_ERROR_CODE,
  PROFILE_DESCRIPTION_CONTAINS_USERNAME_ERROR_CODE,
  STORE_DUPLICATE_URL_ERROR_CODE,
} from '@/screens/create-profile-page/components/profile-description-panel/components/business-description-input/constants'

/**
 * Gets error messages and validity from the
 * @param {AxiosError} businessDescriptionValidationError error object from the request
 * @returns {object} object containing errors, validity, and if an unmapped error occurred
 */
export function useProfileDescriptionValidation(
  businessDescriptionValidationResult?,
  businessDescriptionValidationError?: Error,
) {
  const [businessDescriptionErrors, setBusinessDescriptionErrors] = useState([])
  const [isValid, setIsValid] = useState(true)
  const [unmappedErrorOccurred, setUnmappedErrorOccurred] = useState(false)

  const getContent = usePrefixedContent('hooks.error-handling.index.')

  const genericErrorMessageText = getContent('genericErrorMessage')
  const invalidBusinessDescriptionContentErrorText = getContent(
    'invalidBusinessDescriptionContentError',
  )
  const noJavaScriptErrorText = getContent('noJavaScriptError')
  const websiteAssociationErrorMessageText = getContent(
    'websiteAssociationErrorMessage',
  )
  const invalidUrlErrorMessageText = getContent('invalidUrlErrorMessage')

  useEffect(() => {
    setUnmappedErrorOccurred(false)
    if (businessDescriptionValidationError) {
      logError({ message: 'Error occurred in businessDescription validation' })

      setIsValid(false)

      if (businessDescriptionValidationError.status === 500) {
        setUnmappedErrorOccurred(true)
      } else {
        const errorMessageMap: { [key: string]: string } = {
          [PROFILE_DESCRIPTION_CONTAINS_PHONE_NUMBER_ERROR_CODE]:
            invalidBusinessDescriptionContentErrorText,
          [PROFILE_DESCRIPTION_CONTAINS_EMAIL_ERROR_CODE]:
            invalidBusinessDescriptionContentErrorText,
          [PROFILE_DESCRIPTION_CONTAINS_URL_ERROR_CODE]:
            invalidBusinessDescriptionContentErrorText,
          [PROFILE_DESCRIPTION_CONTAINS_USERNAME_ERROR_CODE]:
            invalidBusinessDescriptionContentErrorText,
          [PROFILE_DESCRIPTION_CONTAINS_HTML_TAG_ERROR_CODE]:
            noJavaScriptErrorText,
          [STORE_DUPLICATE_URL_ERROR_CODE]: websiteAssociationErrorMessageText,
          [INVALID_STORE_URL_ERROR_CODE]: invalidUrlErrorMessageText,
        }

        const foundErrors =
          businessDescriptionValidationError.response.data?.details?.reduce(
            (errorMessages: string[], detail: { error_code: string }) => {
              const errorMessage =
                errorMessageMap[detail.error_code] || genericErrorMessageText
              if (errorMessage && detail.error_code) {
                errorMessages.push(errorMessage)
              }
              return errorMessages
            },
            [],
          )

        setBusinessDescriptionErrors(Array.from(new Set(foundErrors)))
      }
    } else if (businessDescriptionValidationResult) {
      setIsValid(true)
      setBusinessDescriptionErrors([])
    }
  }, [
    businessDescriptionValidationResult,
    businessDescriptionValidationError,
    invalidBusinessDescriptionContentErrorText,
    noJavaScriptErrorText,
    genericErrorMessageText,
    websiteAssociationErrorMessageText,
    invalidUrlErrorMessageText,
  ])

  return { businessDescriptionErrors, isValid, unmappedErrorOccurred }
}
