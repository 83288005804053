export const LOADING_CONTAINER_TEST_ID = 'loading-container'
export const WITH_LOADING_SHIMMER_ROW_CONTAINER_TEST_ID =
  'with-loading-shimmer-row-container'
export const BUSINESS_NAME_SECTION_TEST_ID = 'business-name-section'
export const LOCATION_NAME_SECTION_TEST_ID = 'location-name-section'
export const CONTACT_INFO_SECTION_TEST_ID = 'contact-info-section'

// FullWidthContainer START
export const FULL_WIDTH_CONTAINER_TEST_ID = 'full-width-container'
// FullWidthContainer END

// PanelSliderWithProgressSteppers START
export const PANEL_SLIDER_WITH_PROGRESS_STEPPERS_TEST_ID =
  'panel-slider-with-progress-steppers-container'
// PanelSliderWithProgressSteppers END

// Profile START
export const PROFILE_CARD_CONTAINER_TEST_ID = 'profile-card-container'
export const PROFILE_PHOTO_IMAGE_CONTAINER_TEST_ID =
  'profile-photo-image-container'
export const PROFILE_PHOTO_AVATAR_TEST_ID = 'profile-photo-avator'
export const PROFILE_PHOTO_IMAGE_TEST_ID = 'profile-photo-image'
export const PROFILE_PHOTO_IMAGE_OVERLAY_TEST_ID = 'profile-photo-image-overlay'
export const PROFILE_CARD_BUSINESS_NAME_HEADER_TEST_ID =
  'profile-card-business-name-header'
export const PROFILE_CARD_BUSINESS_HANDLE_NAME_TEST_ID =
  'profile-card-business-handle-name'
export const PROFILE_CARD_BUSINESS_DESCRIPTION_TEST_ID =
  'profile-card-business-description'
export const PROFILE_CARD_BUSINESS_ADDRESS_TEST_ID =
  'profile-card-business-address'
export const PROFILE_CARD_PAYPAL_JOIN_DATE_TEST_ID =
  'profile-card-paypal-join-date'
export const COVER_PHOTO_IMAGE_CONTAINER_TEST_ID = 'cover-photo-image-container'
export const COVER_PHOTO_IMAGE_TEST_ID = 'cover-photo-image'
export const COVER_PHOTO_IMAGE_OVERLAY_TEST_ID = 'cover-photo-image-overlay'
export const COVER_PHOTO_IMAGE_ELEMENT_TEST_ID = 'cover-photo-image-element'
export const PHONE_NUMBER_ICON_SECTION_TEST_ID = 'phone-number-icon-section'
export const EMAIL_ICON_SECTION_TEST_ID = 'email-icon-section'
export const ADDRESS_ICON_SECTION_TEST_ID = 'address-icon-section'
export const WEBSITE_ICON_SECTION_TEST_ID = 'website-icon-section'
export const PROFILE_PAY_BUTTON_TEST_ID = 'profile-pay-button'
export const SUBSCRIPTION_BUTTON_TEST_ID = 'subscription-button'
export const SUBSCRIPTION_BUTTON_LOADING_SPINNER_TEST_ID =
  'subscription-button-loading-spinner'
// Profile END

// FileUploader START
export const FILE_UPLOADER_CONTAINER_TEST_ID = 'file-uploader-container'
// FileUploader END

// ImageCropper START
export const IMAGE_CROPPER_CONTAINER_TEST_ID = 'image-cropper-container'
// ImageCropper END

// NextAndBackButtons START
export const NEXT_AND_BACK_BUTTONS_CONTAINER_TEST_ID =
  'next-and-back-buttons-container'
export const NEXT_BUTTON_TEST_ID = 'next-button'
// NextAndBackButtons END

// UnavailableFeatureScreen START
export const UNAVAILABLE_FEATURE_SCREEN_CONTAINER_TEST_ID =
  'unavailable-feature-screen-container'
// UnavailableFeatureScreen  END

// ShareProfileSheetContainer START
export const SHARE_PROFILE_SHEET_CONTAINER_TEST_ID =
  'share-profile-sheet-container'
// ShareProfileSheetContainer END
