// REDUCERS
import businessProfileReducer from './features/business-profile-slice'
import createProfileReducer from './features/create-profile-slice'
import globalReducer from './features/global-slice'

// REDUCER NAME KEYS
import {
  BUSINESS_PROFILE_REDUCER,
  CREATE_PROFILE_REDUCER,
  GLOBAL_REDUCER,
} from '../constants/redux'

// all reducers should be added to this root reducer
const rootReducer = {
  [BUSINESS_PROFILE_REDUCER]: businessProfileReducer,
  [CREATE_PROFILE_REDUCER]: createProfileReducer,
  [GLOBAL_REDUCER]: globalReducer,
}

export default rootReducer
