import React, { ReactNode } from 'react'

// EXTERNAL DEPENDENCIES
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

// HELPERS
import { logError } from '@/utils/client-error'

// COMPONENTS
import ErrorPage from '@/screens/error-page'

// TYPES
interface ErrorBoundaryProps {
  children: ReactNode
}

/**
 * Error Boundary to render the ErrorPage and log errors to CAL
 * @param children components that the boundary will catch errors from
 */
function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const onError = (error: Error, info: { componentStack: string }) => {
    logError({
      message: 'error boundary caught: ' + error.message,
      stack: info.componentStack,
    })
  }

  return (
    <ReactErrorBoundary fallback={<ErrorPage />} onError={onError}>
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
