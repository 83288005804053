// EXTERNAL DEPENDENCIES
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// CONSTANTS
import {
  CREATE_PROFILE_REDUCER,
  FULL_ADDRESS_ADDRESS_PREFERENCE,
} from '@/constants'
import {
  businessInformationPanelId,
  profileDescriptionPanelId,
  profilePhotosPanelId,
} from '@/screens/create-profile-page/constants'

// TYPES
import {
  FormData,
  InputKeys,
  InputValue,
  PanelInput,
  ProfileDetails,
  initialCreateProfileReduxStateTypes,
} from './types'
import { LocationDisplayPreference } from '@/types'

export const initialCreateProfileReduxState: initialCreateProfileReduxStateTypes =
  {
    currentPanelIndex: 0,
    pageIsLoading: false,
    profileDetailsAreLoading: false,
    errorMessage: '',
    unmappedErrorOccurred: false,
    retryUnmappedError: false,
    profileDetails: {
      businessName: '',
      businessAddress: {},
      contactInfo: {},
    } as ProfileDetails,
    formData: {
      [profileDescriptionPanelId]: {
        businessHandle: { value: '', errors: [], isValid: false },
        businessDescription: { value: '', errors: [], isValid: true },
      },
      [businessInformationPanelId]: {
        selectedLocationDisplayPreference: {
          value: FULL_ADDRESS_ADDRESS_PREFERENCE as LocationDisplayPreference,
          errors: [],
          isValid: true,
        },
        selectedContactInfo: {
          value: {},
          errors: [],
          isValid: false,
        },
      },
      [profilePhotosPanelId]: {
        profilePhoto: {
          value: '',
          errors: [],
          isValid: true,
        },
        coverPhoto: {
          value: '',
          errors: [],
          isValid: true,
        },
      },
    } as FormData,
  }

const createProfileSlice = createSlice({
  name: CREATE_PROFILE_REDUCER,
  initialState: initialCreateProfileReduxState,
  reducers: {
    updatePageIsLoading: (state, action: PayloadAction<boolean>) => {
      state.pageIsLoading = action.payload
    },
    updateErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
    updateUnmappedErrorOccurred: (state, action: PayloadAction<boolean>) => {
      state.unmappedErrorOccurred = action.payload
    },
    updateRetryUnmappedError: (state, action: PayloadAction<boolean>) => {
      state.retryUnmappedError = action.payload
    },
    updateInput: (state, action: PayloadAction<PanelInput>) => {
      const { currentPanelIndex } = state

      const [[key, value]] = Object.entries(action.payload) as [
        key: InputKeys,
        value: InputValue,
      ][]

      state.formData[currentPanelIndex][key] = {
        ...state.formData[currentPanelIndex][key],
        ...value,
      }
    },
    updateInputValue: (state, action: PayloadAction<object>) => {
      const { currentPanelIndex } = state
      const [[key, value]] = Object.entries(action.payload) as [
        key: InputKeys,
        value: InputValue['value'],
      ][]

      state.formData[currentPanelIndex][key].value = value
    },
    updateInputErrors: (state, action: PayloadAction<object>) => {
      const { currentPanelIndex } = state
      const [[key, value]] = Object.entries(action.payload) as [
        key: InputKeys,
        value: InputValue['errors'],
      ][]

      state.formData[currentPanelIndex][key].errors = value
    },
    updateInputValidity: (state, action: PayloadAction<object>) => {
      const { currentPanelIndex } = state
      const [[key, value]] = Object.entries(action.payload) as [
        key: InputKeys,
        value: boolean,
      ][]

      state.formData[currentPanelIndex][key].isValid = value
    },
    updateProfileDetails: (
      state,
      action: PayloadAction<Partial<ProfileDetails>>,
    ) => {
      state.profileDetails = { ...state.profileDetails, ...action.payload }
    },
    updateCurrentPanelIndex: (state, action: PayloadAction<number>) => {
      state.currentPanelIndex = action.payload
    },
    updateProfileDetailsAreLoading: (state, action: PayloadAction<boolean>) => {
      state.profileDetailsAreLoading = action.payload
    },
  },
})

export const {
  updatePageIsLoading,
  updateErrorMessage,
  updateUnmappedErrorOccurred,
  updateRetryUnmappedError,
  updateInput,
  updateInputValue,
  updateInputErrors,
  updateInputValidity,
  updateProfileDetails,
  updateCurrentPanelIndex,
  updateProfileDetailsAreLoading,
} = createProfileSlice.actions

export default createProfileSlice.reducer
