// EXTERNAL DEPENDENCIES
import { useDispatch } from 'react-redux'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

// REDUX
import { GLOBAL_REDUCER } from '../../../constants/redux'

// TYPES
import { type DispatchFunction } from '../../store'

export const initialGlobalState: {
  toast: { message: string; isSuccess?: boolean }
} = {
  toast: { message: '' },
}

const globalSlice = createSlice({
  name: GLOBAL_REDUCER,
  initialState: initialGlobalState,
  reducers: {
    updateGlobalToast: (
      state,
      action: PayloadAction<typeof initialGlobalState['toast']>,
    ) => {
      const newToast = action.payload || initialGlobalState.toast

      state.toast = newToast
    },
  },
})

export const { updateGlobalToast } = globalSlice.actions

export default globalSlice.reducer

export const useGlobalDispatch: DispatchFunction = useDispatch
