import { createSlice } from '@reduxjs/toolkit'
import { BUSINESS_PROFILE_REDUCER } from '../../../constants/redux'

export const initialBusinessProfileReduxState = {
  profileData: null,
  isPreviewing: false,
  editedProfileData: {
    editPageIsLoading: false,
    errorMessage: '',
    isEditing: false,
    currentPanelIndex: 0,
    areValuesEdited: false,
    values: {
      businessDescription: '',
      businessHandle: '',
      businessName: '',
      businessAddress: {},
      contactInfo: {},
      allContactInfo: {},
      coverPhoto: '',
      profilePhoto: '',
      profilePreferences: {
        locationDisplayPreference: '',
      },
    },
    isValid: {
      businessDescription: true,
      contactInfo: true,
      coverPhoto: true,
      profilePhoto: true,
    },
  },
}

const businessProfileSlice = createSlice({
  reducers: {
    updateProfileData: (state, action) => {
      state.profileData = action.payload
    },
    updateCurrentPanelIndexInEditForm: (state, action) => {
      state.editedProfileData.currentPanelIndex = action.payload
    },
    updateIsEditing: (state, action) => {
      state.editedProfileData.isEditing = action.payload
      state.editedProfileData.currentPanelIndex = 0
    },
    updateIsPreviewing: (state, action) => {
      state.isPreviewing = action.payload
    },
    updateAreValuesEdited: (state, action) => {
      state.editedProfileData.areValuesEdited = action.payload
    },
    updateEditedProfileDataValues: (state, action) => {
      state.editedProfileData.values = {
        ...state.editedProfileData.values,
        ...action.payload,
      }
    },
    updateEditedProfilePreferencesDataValues: (state, action) => {
      state.editedProfileData.values.profilePreferences = {
        ...state.editedProfileData.values.profilePreferences,
        ...action.payload,
      }
    },
    updateEditedProfileDataValidity: (state, action) => {
      state.editedProfileData.isValid = {
        ...state.editedProfileData.isValid,
        ...action.payload,
      }
    },
    updateSubscription: (state, action) => {
      state.profileData.isSubscribed = action.payload
    },
    updateEditPageIsLoading: (state, action) => {
      state.editedProfileData.editPageIsLoading = action.payload
    },
    updateEditPageErrorMessage: (state, action) => {
      state.editedProfileData.errorMessage = action.payload
    },
  },
  name: BUSINESS_PROFILE_REDUCER,
  initialState: initialBusinessProfileReduxState,
})

export const {
  updateProfileData,
  updateCurrentPanelIndexInEditForm,
  updateIsEditing,
  updateIsPreviewing,
  updateAreValuesEdited,
  updateEditedProfileDataValues,
  updateEditedProfilePreferencesDataValues,
  updateEditedProfileDataValidity,
  updateSubscription,
  updateEditPageIsLoading,
  updateEditPageErrorMessage,
} = businessProfileSlice.actions

export default businessProfileSlice.reducer
