// EXTERNAL DEPENDENCIES
import React from 'react'

// HELPERS
import { store } from './redux/store'

// WRAPPERS
import { ContextProvider as WorldReadyContextProvider } from '@paypalcorp/worldready-react'
import { ThemeProvider } from '@emotion/react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// COMPONENTS
import App from './app'
import FraudNet from './common/components/tracking/fraud-net'

// CONSTANTS
import { PAYPAL_THEME } from '@paypalcorp/pp-react'
import { APP_BASE_ROUTE } from './api/constants/routes'
import serverData from './utils/server-data'
const queryClient = new QueryClient()

/**
 * Displays the App component wrapped in all providers
 * @param basename Can be specified for Module Federation, defaults to app base route
 */
export default function AppWrapper({ basename = '' }: { basename?: string }) {
  return (
    <WorldReadyContextProvider locale={serverData?.locality?.locale}>
      <ThemeProvider theme={PAYPAL_THEME}>
        <ReduxProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter
              basename={`${basename}${serverData.requestURI || APP_BASE_ROUTE}`}
            >
              <FraudNet />
              <App />
            </BrowserRouter>
          </QueryClientProvider>
        </ReduxProvider>
      </ThemeProvider>
    </WorldReadyContextProvider>
  )
}
